import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpClientHelper} from '../app.httpclient';
import {AuthenticationHelper} from '../app.authentication';
import {throwError} from 'rxjs/internal/observable/throwError';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class JourneyDefService {
  private httpClient;
  private workflowUrlPrefix = 'api/workflow/';
  private journeyDefUrlPrefix = 'api/workflow/v2/product';
  private productUrl = 'product/' + this.authentication.getSelectedObject();
  private event = 'event/v2/';

  constructor(httpClient: HttpClient, private httpHelper: HttpClientHelper,
              private authentication: AuthenticationHelper, private http: HttpClient) {
    this.httpClient = httpClient;
  }

  getJourneyDefList(params) {
    if (!params.pageNumber) {
      params.pageNumber = 1;
      params.pageSize = 100000;
    }
    const url = this.journeyDefUrlPrefix + '/' + + this.authentication.getSelectedObject() + '/journeyDef/list';
    // old: const url = this.journeyDefUrlPrefix + '/list';
    return this.httpHelper.get(url, params)
      .pipe(
        catchError(this.handleError)
      );
  }

  getJourneyDefsForServiceGroup() {
    const url = this.journeyDefUrlPrefix + '/' + this.authentication.getSelectedObject() + '/journeyDefList'
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  publishJourneyDef(journeyDefId) {
    const url = this.journeyDefUrlPrefix + '/' + journeyDefId + '/publishJourney';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  validateJourneyDef(journeyDefId) {
    const url = this.journeyDefUrlPrefix + '/' + journeyDefId + '/validate';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deployJourneyDef(journeyDefId) {
    const url = this.journeyDefUrlPrefix + '/' + journeyDefId + '/deployJourney';
    return this.httpHelper.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteJourneyDefinition(journeyDefId) {
    const url = this.journeyDefUrlPrefix + '/' + journeyDefId + '/deleteJourney';
    return this.httpHelper.Delete(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveJourneyDef(journeyDefId, data): Observable<any> {
    let url = this.journeyDefUrlPrefix;
    if ( journeyDefId && journeyDefId > 0 ) {
      url += '/' + journeyDefId + '/update';
    } else {
      url += '/create';
    }
    return this.httpHelper.post(url, data)
      .pipe(
        catchError(this.handleError)
      );
  }

  cloneJourneyDefinition(journeyDefId, organizationId) {
    const url = this.journeyDefUrlPrefix + '/' + journeyDefId + '/cloneJourney/' + organizationId;
    return this.httpHelper.put(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    return throwError(error);
  }


}
